import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import '@fontsource/saira-condensed';

function Head({ backgroundImage }) {
  return (
    <Flex
      backgroundBlendMode="multiply"
      backgroundImage={backgroundImage} // Use the prop value for the backgroundImage
      bgPosition="center"
      backgroundAttachment="fixed"
      w="100%"
      h="100vh"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Stack
        alignSelf="center"
        w="full"
        h="full"
        p={[10, 0, 0]}
        justifyContent="center"
        bgColor="#0B0C0C"
        opacity={0.75}
        bgBlendMode="overlay"
      >
        {/* Your content */}
      </Stack>
    </Flex>
  );
}

export default Head;
