import React, { useState } from 'react';
import {
  Stack,
  Heading,
  Link,
  Input,
  Flex,
  Button,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea, // Import the Textarea component
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function ContactUSSection() {
    const [isSubmitting, setIsSubmitting] = useState(false); // Add a loading state

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    // Create an object to represent the API request body
    const requestBody = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      number: values.number,
      subject: values.subject,
      message: values.message,
    };

    try {
      // Make the API request
      const response = await fetch(`${process.env.REACT_APP_CLIENT_URL}contact/add`, {
        method: 'POST', // Use the appropriate HTTP method (e.g., POST)
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody), // Convert the object to JSON
      });
      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        alert('I will connect with you soon');
        formik.resetForm(); // Reset the form
      } else {
        console.error('API Request Error:', response.statusText);
        alert('There is some issue please try again later');
      }
    } catch (error) {
      console.error('API Request Error:', error);
      alert('There is some issue please try again later');
    } finally {
      setIsSubmitting(false); // Set submitting to false regardless of success or failure
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      number: '',
      subject: '',
      message: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      number: Yup.string().required('Required'),
      subject: Yup.string().required('Required'),
      message: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
        // Call handleSubmit to make the API request
        await handleSubmit(values);
      },
  });

  const [isFocused, setIsFocused] = useState({
    firstName: false,
    lastName: false,
    email: false,
    number: false,
    subject: false,
    message: false,
  });

  const handleInputFocus = (fieldName) => {
    setIsFocused({ ...isFocused, [fieldName]: true });
  };

  const handleInputBlur = (fieldName) => {
    if (!formik.values[fieldName]) {
      setIsFocused({ ...isFocused, [fieldName]: false });
    }
  };

  return (
    <Stack bg="black" w="full" h="full" direction={['column', 'column', 'column', 'row']}>
      <Stack p={['20px', '20px', '40px', '80px']} w={['100%', '100%', '100%', '50%']} h="full" spacing={5}>
        <Heading
          as="h4"
          textAlign="left"
          color="white"
          fontFamily="Inria Serif"
          fontSize={['20px', '24px', '28px', '32px']}
          fontWeight={700}
          textTransform="uppercase"
          fontStyle="normal"
          lineHeight="1.4em"
          letterSpacing="0.6px"
        >
          Email Me
        </Heading>
        <Link href='mailto:contact@rohitkandoth.com'>
          <Heading
            as="h4"
            textAlign="left"
            color="#D2C1AF"
            fontFamily="Inria Serif"
            fontSize={['20px', '20px', '20px', '22px']}
            fontWeight={600}
            textTransform="uppercase"
            fontStyle="normal"
            lineHeight="1.4em"
            letterSpacing="0.6px"
            textDecoration="underline"
          >
            contact@rohitkandoth.com
          </Heading>
        </Link>
        <Heading
          as="h4"
          textAlign="left"
          color="white"
          fontFamily="Inria Serif"
          fontSize={['20px', '24px', '28px', '32px']}
          fontWeight={700}
          textTransform="uppercase"
          fontStyle="normal"
          lineHeight="1.4em"
          letterSpacing="0.6px"
          pt={10}
        >
          Call
        </Heading>
        <Link href='tel:+44 7778675778'>
          <Heading
            as="h4"
            textAlign="left"
            color="#D2C1AF"
            fontFamily="Inria Serif"
            fontSize={['20px', '20px', '20px', '22px']}
            fontWeight={600}
            textTransform="uppercase"
            fontStyle="normal"
            lineHeight="1.4em"
            letterSpacing="0.6px"
            textDecoration="underline"
          >
            +44 7778675778
          </Heading>
        </Link>
        <Heading
          as="h4"
          textAlign="left"
          color="white"
          fontFamily="Inria Serif"
          fontSize={['20px', '24px', '28px', '32px']}
          fontWeight={700}
          textTransform="uppercase"
          fontStyle="normal"
          lineHeight="1.4em"
          letterSpacing="0.6px"
          pt={10}
        >
          Visit at
        </Heading>
        <Link href=''>
          <Heading
            as="h4"
            textAlign="left"
            color="#D2C1AF"
            fontFamily="Inria Serif"
            fontSize={['20px', '20px', '20px', '22px']}
            fontWeight={600}
            textTransform="uppercase"
            fontStyle="normal"
            lineHeight="1.2em"
            letterSpacing="1px"
          >
            3 Explorer Way, Mill Hill East, NW7 1UR London-United Kingdom
          </Heading>
        </Link>
      </Stack>
      <Flex p={['20px', '20px', '40px', '80px']} w={['100%', '100%', '100%', '50%']} h="full" direction="column" spacing={5}>
        <Heading
          as="h4"
          textAlign="left"
          color="white"
          fontFamily="Inria Serif"
          fontSize={['28px', '32px', '36px', '40px']}
          fontWeight={700}
          textTransform="uppercase"
          fontStyle="normal"
          lineHeight="1.2em"
        >
          Let's Work together
        </Heading>
        <VStack as="form" onSubmit={formik.handleSubmit} w="full" spacing="2px" alignItems="baseline">
          <Flex w={["90%", "90%", "70%", "100%"]} direction={['column', 'column', 'row', 'row']} gap={2} justifyContent="space-between" marginTop="10px">
            <FormControl
              isInvalid={formik.touched.firstName && formik.errors.firstName}
              w={['100%', '100%', '80%', '45%']}
            >
              <FormLabel
                htmlFor="firstName"
                style={{
                  color: isFocused.firstName || formik.values.firstName ? 'white' : 'transparent',
                  transition: 'color 0.3s',
                }}
              >
                First Name
              </FormLabel>
              <Input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                onFocus={() => handleInputFocus('firstName')}
                onBlur={() => handleInputBlur('firstName')}
                {...formik.getFieldProps('firstName')}
                size="md"
                rounded="2px"
                backgroundColor="white"
              />
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={formik.touched.lastName && formik.errors.lastName}
              w={['100%', '100%', '80%', '45%']}
            >
              <FormLabel
                htmlFor="lastName"
                style={{
                  color: isFocused.lastName || formik.values.lastName ? 'white' : 'transparent',
                  transition: 'color 0.3s',
                }}
              >
                Last Name
              </FormLabel>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                onFocus={() => handleInputFocus('lastName')}
                onBlur={() => handleInputBlur('lastName')}
                {...formik.getFieldProps('lastName')}
                size="md"
                rounded="2px"
                backgroundColor="white"
              />
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            </FormControl>
          </Flex>
          <FormControl
            isInvalid={formik.touched.email && formik.errors.email}
            w={["90%", "90%", "70%", "100%"]}
          >
            <FormLabel
              htmlFor="email"
              style={{
                color: isFocused.email || formik.values.email ? 'white' : 'transparent',
                transition: 'color 0.3s',
              }}
            >
              Email
            </FormLabel>
            <Input
              type="text"
              id="email"
              name="email"
              placeholder="Email"
              onFocus={() => handleInputFocus('email')}
              onBlur={() => handleInputBlur('email')}
              {...formik.getFieldProps('email')}
              size="md"
              rounded="2px"
              backgroundColor="white"
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={formik.touched.number && formik.errors.number}
            w={["90%", "90%", "70%", "100%"]}
          >
            <FormLabel
              htmlFor="number"
              style={{
                color: isFocused.number || formik.values.number ? 'white' : 'transparent',
                transition: 'color 0.3s',
              }}
            >
              Number
            </FormLabel>
            <Input
              type="text"
              id="number"
              name="number"
              placeholder="Number"
              onFocus={() => handleInputFocus('number')}
              onBlur={() => handleInputBlur('number')}
              {...formik.getFieldProps('number')}
              size="md"
              rounded="2px"
              backgroundColor="white"
            />
            <FormErrorMessage>{formik.errors.number}</FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={formik.touched.subject && formik.errors.subject}
            w={["90%", "90%", "70%", "100%"]}
          >
            <FormLabel
              htmlFor="subject"
              style={{
                color: isFocused.subject || formik.values.subject ? 'white' : 'transparent',
                transition: 'color 0.3s',
              }}
            >
              Subject
            </FormLabel>
            <Input
              type="text"
              id="subject"
              name="subject"
              placeholder="Subject"
              onFocus={() => handleInputFocus('subject')}
              onBlur={() => handleInputBlur('subject')}
              {...formik.getFieldProps('subject')}
              size="md"
              rounded="2px"
              backgroundColor="white"
            />
            <FormErrorMessage>{formik.errors.subject}</FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={formik.touched.message && formik.errors.message}
            w={["90%", "90%", "70%", "100%"]}
          >
            <FormLabel
              htmlFor="message"
              style={{
                color: isFocused.message || formik.values.message ? 'white' : 'transparent',
                transition: 'color 0.3s',
              }}
            >
              Message
            </FormLabel>
            <Textarea
              id="message"
              name="message"
              placeholder="Message"
              onFocus={() => handleInputFocus('message')}
              onBlur={() => handleInputBlur('message')}
              {...formik.getFieldProps('message')}
              size="md"
              rounded="2px"
              backgroundColor="white"
            />
            <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
          </FormControl>
          <Button type="submit" alignSelf="center" style={{ marginTop: "10px" }} isLoading={isSubmitting}>Submit</Button>
        </VStack>
      </Flex>
    </Stack>
  );
}

export default ContactUSSection;
