import React from 'react';
import HomeNavbar from '../Component/HomeNavbar'; 
import SafariCard from '../Component/SafariCard';
import Footer from '../Component/Footer';
import WildlifeHead from '../Component/WildlifeHead';
import { Helmet } from 'react-helmet';

function Tours() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Safari</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <HomeNavbar />
      <WildlifeHead />
      <SafariCard />
      <Footer />
    </>
  );
}

export default Tours;
