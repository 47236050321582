import { Button, Heading, HStack, Stack, Text, theme , Link, Spacer} from '@chakra-ui/react'
import React from 'react'
import "@fontsource/inria-serif"
import { EmailIcon, PhoneIcon, ChevronRightIcon } from '@chakra-ui/icons'
import "@fontsource/sen"
import { ImLocation2 } from "react-icons/im";
import { BiLogoFacebookCircle , BiLogoInstagramAlt } from "react-icons/bi";

// ImLocation2

function Footer() {
  return (
    <Stack direction="column">
    <Stack direction={["column","column","row","row"]} bgColor={theme.colors.black} w="100%" >
      {/* columnn 0 */}
      <Stack w={[0,0,"5%","5%"]} display={["none","none","flex","flex"]}></Stack>
      {/* column 1 */}
      <Stack w={["100%","100%","50%","20%"]} alignSelf="center" justifyContent="center"  >
        <Text pt="80px" pb="80px"  pl={["30px","30px","80px","0px"]} fontFamily="Inria Serif" fontSize="16px" 
          mb={[0,0,"60px","60px"]} mt="30px" color="#F5F8FFC9" alignSelf="center" lineHeight="1.5em" letterSpacing="0.4px" >
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Aenean lacinia bibendum.
        </Text>
      </Stack>
      {/* this below stack for tab view  */}
      {/* <Stack direction={["column","column","row","column"]}>  */}

      {/* column 2 */}
        <Stack w={["100%","100%","50%","25%"]} p={[5,15,0,0]} alignSelf="center" mt="50px" ml="40px" mb="40px" pr={[0,0,"40px","40px"]} spacing={5} >
            <Heading as="h3" fontFamily="Inria Serif" fontSize="24px" fontWeight={700} textTransform="uppercase" 
              fontStyle="normal" lineHeight="1.4em" letterSpacing="0.6px" color="white" >
                contact info
            </Heading>
              <HStack >
            <Link href='mailto:contact@rohitkandoth.com' lineHeight="1.5em"  >
                <EmailIcon color="white" fontSize="16px" />
                <Button fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px" variant="link" color="white" alignSelf="flex-start">
                    contact@rohitkandoth.com
                </Button>
            </Link>
              </HStack>
            <Link href='tel:+44 7778675778' lineHeight="1.5em"  >
              <HStack>
                <PhoneIcon color="white" fontSize="16px" />
                <Button fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px"  variant="link" color="white" alignSelf="flex-start">
                    +44 7778675778
                </Button>
              </HStack>
            </Link>
            <Link href='https://goo.gl/maps/eTqJ62dy1DmvGC6EA' isExternal lineHeight="1.5em"   >
              <HStack>
                <ImLocation2 color="white" size={32} />
                <Text fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px"  variant="link" color="white"  >
                    3 Explorer Way, Mill Hill East, NW7 1UR London-United Kingdom
                </Text>
              </HStack>
            </Link>
        </Stack>
        {/* column 3 */}
        <Stack display={["flex","flex","none","flex"]}  w={["100%","100%","0%","25%"]} p={[5,15,0,0]} alignSelf="center" mt="50px" ml="40px" mb="40px" pr={[0,0,"40px","40px"]} spacing={5}> 
            <Heading as="h3" fontFamily="Inria Serif" fontSize="24px" fontWeight={700} textTransform="uppercase" 
              fontStyle="normal" lineHeight="1.4em" letterSpacing="0.6px" color="white" >
                other info
            </Heading>
            <Link href='#' lineHeight="1.5em"  >
              <HStack >
                <ChevronRightIcon color="white" fontSize="16px" />
                <Text fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px" variant="link" color="white" alignSelf="flex-start">
                    Privacy Policy
                </Text>
              </HStack>
            </Link>
            <Link href='#' lineHeight="1.5em"  >
              <HStack>
                <ChevronRightIcon color="white" fontSize="16px" />
                <Button fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px"  variant="link" color="white" alignSelf="flex-start">
                    Terms & Conditions
                </Button>
              </HStack>
            </Link>
            <Link href='#' lineHeight="1.5em"  >
              <HStack>
                <ChevronRightIcon color="white" fontSize="16px" />
                <Button fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px"  variant="link" color="white" alignSelf="flex-start">
                    Credit Disclaimer
                </Button>
              </HStack>
            </Link>
            <Link href='#' lineHeight="1.5em"  >
              <HStack>
                <ChevronRightIcon color="white" fontSize="16px" />
                <Button fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px"  variant="link" color="white" alignSelf="flex-start">
                    Contact Me
                </Button>
              </HStack>
            </Link>
            <Spacer display={["none","none","none","flex"]} h="3px"></Spacer>
        </Stack>
      {/* </Stack> */}
      {/* column 4 */}
      <Stack display={["flex","flex","none","flex"]}  w={["100%","100%","0%","25%"]} p={[5,15,0,0]} alignSelf="center" mt="50px" ml="40px" mb="40px" pr={[0,0,"40px","40px"]} spacing={5} >
          <Heading as="h3" fontFamily="Inria Serif" fontSize="24px" fontWeight={700} textTransform="uppercase" 
            fontStyle="normal" lineHeight="1.4em" letterSpacing="0.6px" color="white" >
              follow me
          </Heading>
          <HStack gap={2}>
            <BiLogoFacebookCircle color="white" fontSize={30}/>
            <BiLogoInstagramAlt color="white" fontSize={30}/>
          </HStack>
      </Stack>
    </Stack>
    {/* for tablet */}
    {/* column 3 */}
    <Stack direction="row"  bgColor={theme.colors.black} w="100%" pl={["0px","0px","80px","0px"]} paddingBottom="20px">
      {/* columnn 0 */}
      <Stack w={[0,0,"5%","5%"]} display={["none","none","flex","flex"]}></Stack>
    <Stack display={["none","none","flex","none"]}  w={["0%","0%","50%","0%"]} p={[5,15,0,0]} alignSelf="center" mt="50px" ml="40px" mb="40px" pr={[0,0,"40px","40px"]} spacing={5}> 
            <Heading as="h3" fontFamily="Inria Serif" fontSize="24px" fontWeight={700} textTransform="uppercase" 
              fontStyle="normal" lineHeight="1.4em" letterSpacing="0.6px" color="white" >
                other info
            </Heading>
            <Link href='#' lineHeight="1.5em"  >
              <HStack >
                <ChevronRightIcon color="white" fontSize="16px" />
                <Text fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px" variant="link" color="white" alignSelf="flex-start">
                    Privacy Policy
                </Text>
              </HStack>
            </Link>
            <Link href='#' lineHeight="1.5em"  >
              <HStack>
                <ChevronRightIcon color="white" fontSize="16px" />
                <Button fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px"  variant="link" color="white" alignSelf="flex-start">
                    Terms & Conditions
                </Button>
              </HStack>
            </Link>
            <Link href='#' lineHeight="1.5em"  >
              <HStack>
                <ChevronRightIcon color="white" fontSize="16px" />
                <Button fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px"  variant="link" color="white" alignSelf="flex-start">
                    Credit Disclaimer
                </Button>
              </HStack>
            </Link>
            <Link href='#' lineHeight="1.5em"  >
              <HStack>
                <ChevronRightIcon color="white" fontSize="16px" />
                <Button fontFamily="Sen" ml="12px" fontSize="16px" fontWeight={400} fontStyle="normal" 
                  letterSpacing="0.4px"  variant="link" color="white" alignSelf="flex-start">
                    Contact Me
                </Button>
              </HStack>
            </Link>
            <Spacer display={["none","none","none","flex"]} h="3px"></Spacer>
        </Stack>
      {/* </Stack> */}
      {/* column 4 */}
      <Stack display={["none","none","flex","none"]}  w={["0%","0%","50%","0%"]} p={[5,15,0,0]} alignSelf="center" mt="50px" ml="40px" mb="40px" pr={[0,0,"40px","40px"]} spacing={5} >
          <Heading as="h3" fontFamily="Inria Serif" fontSize="24px" fontWeight={700} textTransform="uppercase" 
            fontStyle="normal" lineHeight="1.4em" letterSpacing="0.6px" color="white" >
              follow me
          </Heading>
          <HStack gap={2}>
            <BiLogoFacebookCircle color="white" fontSize={30}/>
            <BiLogoInstagramAlt color="white" fontSize={30}/>
          </HStack>
      </Stack>
      </Stack>
    </Stack>
  )
}

export default Footer