import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridItem,
  extendTheme,
  Stack,
  Card,
  CardBody,
  Image,
  Heading,
  Text,
  Divider,
  CardFooter,
  ButtonGroup,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import axios from 'axios';
import SpecificTour from '../Pages/SpecificTour';
import { Link } from 'react-router-dom';

export default function SafariCard() {
  const gridTemplate = useBreakpointValue({
    base: '1fr',
    md: 'repeat(2, 1fr)',
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from your API endpoint
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CLIENT_URL}Safari/layout`
        );
        setData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid
      templateColumns={gridTemplate}
      gap={6}
      justifyItems="center"
      padding={10}
    >
      {data.map(item => (
        <GridItem
          key={item._id}
          maxW="sm"
          borderWidth="1px"
          bg="white"
          borderRadius="lg"
          overflow="hidden"
        >
          <Image
            src={`${process.env.REACT_APP_CLIENT_URL}${item.featured_banner}`}
            alt={item.heading}
          />
          <div style={{ padding: '1rem', gap: 10 }}>
            <Heading size="lg">{item.heading}</Heading>
            <Text>{item.tagline}</Text>
            <Text fontSize="lg" style={{ display: 'flex', gap: 5 }}>
              Straring Price <Text fontWeight="600">{item.price}</Text>
            </Text>
          </div>
          <Divider />
          <div style={{ padding: '1rem' }}>
            <Link to={`/safari/${item._id}`}>
              <Button colorScheme="#F5F8FFC9" color="#D2C1AF">
                Know More
              </Button>
            </Link>
          </div>
        </GridItem>
      ))}
    </Grid>
  );
}
