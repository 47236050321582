import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import AboutMe from './Pages/AboutMe';
import ContactMe from './Pages/ContactMe';
import Photo from './Pages/Photo';
import Login from './Pages/Login';
import Cookies from 'js-cookie';
import Register from './Pages/Register';
import Home from './Pages/Home';
import Cart from './Pages/Cart';
import SpecificTour from './Pages/SpecificTour';
import Gallery from './Pages/Gallery';
import Tours from './Pages/Tours';

function Router() {
  const user= Cookies.get('userToken', { expires: 1 / 48 });
  let { userId } = useParams();
  let redirect = window.location;

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<ContactMe />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/tours" element={<Tours />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/safari/:id" element={<SpecificTour />} />
        {user ? <Route path="/photo/:userId" element={<Photo />} /> : <Route path="/photo/:userId" element={<Navigate replace to={"/login/?redirect="+redirect.pathname.replaceAll('/','%2F')} />} />}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
