import React, { useState, useEffect } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import axios from 'axios';
import { Flex, Heading, Stack } from '@chakra-ui/react';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';

export default function PortfolioGallery() {
  const [images, setImages] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CLIENT_URL}get-all-watermark-images/wildlife`
      )
      .then(response => {
        const fetchedImages = response.data; // Assuming the response data matches the structure you provided
        setImages(fetchedImages);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
        setIsLoading(false);
      });

    const handleContextmenu = e => {
      e.preventDefault();
      alert('Right-click is disabled.');
    };

    const handleKeydown = e => {
      // Prevent common keyboard shortcuts (e.g., F12, Ctrl+Shift+I)
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i'))
      ) {
        e.preventDefault();
        alert('Keyboard shortcuts are disabled.');
      }
    };

    document.addEventListener('contextmenu', handleContextmenu);
    document.addEventListener('keydown', handleKeydown);

    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const openLightbox = index => {
    setLightboxOpen(true);
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxIndex(0);
  };

  const goToNext = () => {
    setLightboxIndex((lightboxIndex + 1) % images.length);
  };

  const goToPrev = () => {
    setLightboxIndex((lightboxIndex + images.length - 1) % images.length);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Stack padding="5" backgroundColor="black">
            {images.length === 0 ? (
              <Flex justifyContent="center">
                <p style={{ color: 'white' }}>No data available.</p>
              </Flex>
            ) : (
              <>
                <Flex justifyContent="center"></Flex>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry gutter="20px">
                    {images.slice(0, 6).map((image, i) => (
                      <img
                        key={image._id} // Use a unique key, like _id
                        src={`https://api.rohitkandothphotography.com/uploads/${image.original_file[0]}`}
                        alt={image.pic_name}
                        style={{
                          width: '100%',
                          display: 'block',
                          cursor: 'pointer',
                        }}
                        onClick={() => openLightbox(i)}
                        className="animated-image"
                      />
                    ))}
                  </Masonry>
                </ResponsiveMasonry>
              </>
            )}
          </Stack>
        </>
      )}

      {lightboxOpen && (
        <Lightbox
          mainSrc={`https://api.rohitkandothphotography.com/uploads/${images[lightboxIndex].original_file[0]}`}
          onCloseRequest={closeLightbox}
          prevSrc={
            images[(lightboxIndex + images.length - 1) % images.length]
              .original_file[0]
          }
          nextSrc={images[(lightboxIndex + 1) % images.length].original_file[0]}
          onMovePrevRequest={goToPrev}
          onMoveNextRequest={goToNext}
        />
      )}
    </>
  );
}
