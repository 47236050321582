import React, { useState } from 'react';
import {
  Flex,
  Image,
  Box,
  Button,
  Link as ChakraLink,
  IconButton,
  Icon,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';

function CommonNavBar() {
  const primaryColor = '#2D67AB';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isActive = path => {
    const currentPath = window.location.pathname;
    const dynamicRouteRegex = new RegExp(`^${path}/\\d+$`);

    return (
      currentPath === path ||
      currentPath.startsWith(`${path}/`) ||
      dynamicRouteRegex.test(currentPath)
    );
  };

  return (
    <>
     <Flex direction="row" justifyContent="space-between" mt="-50px" mb="-50px">
        <Flex alignSelf="center" cursor="pointer" pl={10}>
          <IconButton
            as={HamburgerIcon}
            boxSize={8}
            background="transparent"
            color="#F5F8FFC9"
            alignSelf="center"
            size="md"
            _hover={{ color: '#D2C1AF' }}
            transition="color 0.3s ease-in-out"
            onClick={onOpen}
          />
          <Box display={['none', 'none', 'none', 'flex']}>
            <Text color="#F5F8FFC9" fontWeight="bold">
              Menu
            </Text>
          </Box>
        </Flex>

        <Flex
          w={['100%', '100%', '100%', '100%']}
          justifyContent="center"
          align="center"
          ml={-10}
        >
          <Image
            src="../assest/logos.png"
            w={['250px', '250px', '250px', '300px']}
            h={['210px', '210px', '220px', '300px']}
            alignSelf="center"
          />
        </Flex>
      </Flex>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}  >
        <DrawerOverlay />
        <DrawerContent background="black">
          <DrawerCloseButton color="white" />
          <DrawerHeader color="white" size="lg" >Menu</DrawerHeader>

          <DrawerBody>
            {/* Navigation Header */}
           
              {/* <Flex ml="5" mr="5" w="50%">
                <Button
                  pl="16px"
                  pr="16px"
                  _hover={{ color: '#F5F8FFC9' }}
                  color={isActive('/') ? primaryColor : '#F5F8FFC9'}
                  fontFamily="Saira Condensed"
                  fontSize={['30px', '30px', '35px', '40px', '45px']}
                  variant="link"
                  alignSelf="center"
                  fontWeight={600}
                  lineHeight="1.3em"
                  letterSpacing="0.4px"
                  fontStyle="normal"
                >
                  Menu
                </Button>
              </Flex> */}

              {/* <Flex
                w="50%"
                justifyContent="end"
                cursor="pointer"
                // onClick={() => setDisplay('none')}
              >
                <Box
                  mt="10"
                  borderRadius="50%"
                  bg={primaryColor}
                  mr={10}
                  p="25"
                >
                  <Icon size="sm" as={CloseIcon} color="white"></Icon>
                </Box>
              </Flex> */}

              {/* Navigation Links */}

              <Flex flexDirection="column" align="left" ml="5" >
                {['/', '/tours', '/gallery', '/about', '/contact'].map(path => (
                  <ChakraLink
                    key={path}
                    p={3}
                    mr="5"
                    borderBottom="solid"
                    borderBottomColor="#c4c4c4"
                    borderBottomWidth="1px"
                    _hover="none"
                    as={Link}
                    to={path}
                  >
                    <Button
                      fontFamily="Saira Condensed"
                      _hover={{ color: primaryColor }}
                      color={isActive(path) ? primaryColor : '#F5F8FFC9'}
                      fontSize={['20px', '20px', '25x', '30px', '35px']}
                      variant="link"
                      alignSelf="center"
                      fontWeight={600}
                      lineHeight="1.3em"
                      letterSpacing="0.4px"
                      fontStyle="normal"
                    >
                      {path === '/' ? 'HOME' : path.toUpperCase().substring(1)}
                    </Button>
                  </ChakraLink>
                ))}
              </Flex>
           
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CommonNavBar;
