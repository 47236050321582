import { extendTheme } from '@chakra-ui/react';
import "@fontsource/roboto-serif";
import "@fontsource/sen";
import "@fontsource/source-serif-4";

const theme = extendTheme({
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
  headigFont: {
    fontFamily: "Inria Serif",
    fontSize: ["16px","40px","72px"],
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: ["1.4em","1.2em","1.3em"]
  },
  fonts: {
    body: 'sen',
    heading: 'roboto-serif',
  },
  fontSizes: {
    sm: ['14px', '16px'],
    md: ['16px', '18px'],
    lg: ['18px', '20px','25px'],
    xl: ['20px', '24px'],
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  colors: {
    primary: '#2E86AB',
    secondary: '#F7DC6F',
  },
  styles: {
    colors: {
      primary: '#D2C1AF',
      secondary: '#FFFFFF',
      text: '#191B1D',
      accent: '#F5F8FFC9',
      black: "#0B0C0C"
    },
    global: {
      // Base styles for all devices
      body: {
        fontFamily: 'body',
        fontWeight: 'normal',
        fontSize: ['md', 'lg'], // Responsive font size
        color: 'gray.800',
        bg: 'black',
      },
      // Styles for smaller screens
      '@media screen and (max-width: 30em)': {
        body: {
          fontSize: ['sm', 'md'], // Responsive font size
        },
      },
      // Styles for larger screens
      '@media screen and (min-width: 48em)': {
        body: {
          fontSize: ['lg', 'xl'], // Responsive font size
        },
      },
    },
  },
});

export default theme;
