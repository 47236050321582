import React from 'react';
import CommonHead from '../Component/HomeNavbar';
import ContactHead from '../Component/ContactHead';
import ContactUSSection from '../Component/ContactUSSection';
import Footer from '../Component/Footer';
import { Helmet } from 'react-helmet';
// import ContactHead from '../Component/CommonHead'

function ContactMe() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Me</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <CommonHead />
      <ContactHead />
      <ContactUSSection />
      <Footer />
    </>
  );
}

export default ContactMe;
