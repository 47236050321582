import React from 'react';
import HomeNavbar from '../Component/HomeNavbar';
// import ContactHead from '../Component/CommonHead'
import Cta from '../Component/Cta';
import Footer from '../Component/Footer';
// import Gallery from '../Component/Gallery'
import HeadShot from '../Component/HeadShot';
import { Helmet } from 'react-helmet';
import HomeHead from '../Component/HomeHead';
import { Box, Img, Stack } from '@chakra-ui/react';

function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coming Soon</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      {/* <HomeNavbar /> */}
      {/* desktop home head */}
      {/* <Stack display={['none', 'none', 'flex', 'flex', 'flex']}>
        <HomeHead
          backgroundImages={[
            'assest/home/02-Desktop.jpg',
            'assest/home/09-Desktop.jpg',
            'assest/home/05-Desktop.jpg',
            'assest/home/03-Desktop.jpg',
            'assest/home/07-Desktop.jpg',
          ]}
        />
      </Stack> */}
      {/* mobile head */}
      {/* <Stack display={['flex', 'flex', 'none', 'none', 'none']}>
        <HomeHead
          backgroundImages={[
            'assest/home/02-Mobile.jpg',
            'assest/home/09-Mobile.jpg',
            'assest/home/05-Mobile.jpg',
            'assest/home/03-Mobile.jpg',
            'assest/home/07-Mobile.jpg',
          ]}
        />
      </Stack>

      <HeadShot />
      <Cta />
      <Footer /> */}
     <Box flexShrink={0}>
        <Img
          w="100%"
          h="auto"
          maxH="100vh"
          objectFit="contain"
          src='./assest/commingSoon.jpeg'
        />
     </Box>
    </>
  );
}

export default Home;
