import { Flex,Stack, Heading  } from '@chakra-ui/react'
import React from 'react'
// import heroBackground from '../assest/img/wildlife_background/bg.jpg'
import "@fontsource/saira-condensed"

function WildlifeHead() {

  return (
    <Flex
    backgroundBlendMode="multiply"
    backgroundImage='/assest/img/wildlife_background/bg.jpg'
    bgPosition="center"
    backgroundAttachment="fixed"
    w="100%"
    h="100vh"
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
  >
    <Stack
      alignSelf="center"
      w="full"
      h="full"
      p={[10, 0, 0]}
      justifyContent="center"
      bgColor="#0B0C0C"
      opacity={0.75}
      bgBlendMode="overlay"
    >
      <Heading fontSize={["4xl", "5xl", "6xl"]} fontWeight="bold" color="white" textAlign="center">
        Explore the Wonders of Wildlife Safari
      </Heading>
    </Stack>
  </Flex>
  )
}

export default WildlifeHead