import { Stack, Flex, theme, Heading } from '@chakra-ui/react';
import React from 'react';
import "@fontsource/inria-serif";
import "@fontsource/saira-condensed";

function ContactHead() {
  return (
    <Flex
      backgroundBlendMode="multiply"
      backgroundColor={theme.colors.blackAlpha[300]}
      color={theme.colors.black}
      backgroundImage="assest/contact.jpg"
      bgPosition="center"
      backgroundAttachment="fixed"
      w="100%"
      h={{ base: "65vh", md: "75vh", lg: "85vh" }}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Stack
        alignSelf="center"
        w="full"
        h="full"
        p={[10, 0, 0]}
        justifyContent="center"
        bgColor="#0B0C0C"
        opacity={0.75}
        bgBlendMode="overlay"
      >
        <Stack w="full" h="full" alignSelf="center" justifyContent="center" marginTop={["60px", "60px", "60px", ""]}>
          <Stack direction={["column", "row", "row"]} justifyContent="center" spacing={[0, 5, 5]}>
            <Heading
              fontSize={["42px", "42px", "52px", "72px"]}
              textAlign="center"
              lineHeight="1.3em"
              fontFamily="Inria Serif"
              fontWeight={700}
              textTransform="uppercase"
              fontStyle="normal"
              color="#D2C1AF"
            >
              Contact
            </Heading>
            <Heading
              fontSize={["42px", "42px", "52px", "72px"]}
              lineHeight="1.3em"
              fontFamily="Inria Serif"
              fontWeight={700}
              textTransform="uppercase"
              fontStyle="normal"
              textAlign="center"
              color="white"
            >
              Me
            </Heading>
          </Stack>
          <Stack justifyContent="center" spacing={[3, 5, 5]}>
            <Heading
              fontSize={["20px", "20px", "24px"]}
              w={["80%", "80%", "100%", "100%"]}
              lineHeight="1.2em"
              fontFamily="Inria Serif"
              color="white"
              fontWeight={600}
              alignSelf="center"
              letterSpacing="2.5px"
              textTransform="uppercase"
              fontStyle="normal"
              textAlign="center"
            >
              I would love to hear what you think
            </Heading>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
}

export default ContactHead;
