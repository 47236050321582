// LoadingSpinner.js
import React from "react";
import "./loading-spinner.css"
import { Flex, Stack } from "@chakra-ui/react";

const LoadingSpinner = () => {
  return (
    <Flex justifyContent="center" alignSelf="center" h="70vh" alignItems="center">
      <div className="loading-spinner "></div>
    </Flex>

  )
};

export default LoadingSpinner;
