import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';

export default function TourContent({ heading, content, tagline }) {
  return (
    <Stack alignItems="center" padding={10}>
      <Card width={["95%","95%","80%","60%","60%"]} border="4px solid #D2C1AF">
        <CardHeader textAlign="center">
          <Heading size="2xl"> {heading}</Heading>
          <Heading size="md" color="gray.600" mt={2}>
            {' '}
            {tagline} 
          </Heading>
        </CardHeader>
        <CardBody>
          {/* <Heading>{}</Heading> */}
          <Text dangerouslySetInnerHTML={{ __html: content }} />
          <Text></Text>
        </CardBody>
        <CardFooter>
          {/* <Button colorScheme="blue">View here</Button> */}
        </CardFooter>
      </Card>
    </Stack>
  );
}
