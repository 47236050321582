import { Stack, Image, Heading, Text, Box } from '@chakra-ui/react';
import React from 'react';
import '@fontsource/inria-serif';
import '@fontsource/sen';

function About() {
  return (
    <Stack bgColor="black" direction={["column", "column", "row", "row"]} w="full" h="full" spacing={[4, 4, 8, 12]}>
      {/* Initial column */}
      <Stack w={["5%", "5%", "0", "0"]} display={["none", "flex", "flex", "flex"]}></Stack>
      {/* Second column */}
      <Stack w={["100%", "100%", "45%"]} align="center" px={[4, 4, 8, 8]}>
        <Image h="auto" w="100%" src="assest/Rohit-Profle.jpg" alignSelf="center" />
      </Stack>
      {/* Third column */}
      <Stack w={["5%", "5%", "0", "0"]} display={["none", "flex", "flex", "flex"]}></Stack>
      {/* Fourth column */}
      <Stack w={["100%", "100%", "45%"]} textAlign="left" pl={[4, 4, 8, 8]} pr={[4, 4, 8, 8]} pt={[4, 4, 8, 8]}>
        <Stack direction={["column", "row", "column"]}>
          <Heading fontFamily="Inria Serif" fontSize={["52px", "52px", "72px", "72px"]} fontWeight={700} textTransform="uppercase" fontStyle="normal" lineHeight="1.3em" color="#D2C1AF">
            Rohit
          </Heading>
          <Heading fontFamily="Inria Serif" fontSize={["52px", "52px", "72px", "72px"]} fontWeight={700} textTransform="uppercase" fontStyle="normal" lineHeight="1.3em" color="white">
            Kandoth
          </Heading>
        </Stack>

        <Heading fontFamily="Inria Serif" fontSize="24px" fontWeight={700} textTransform="uppercase" fontStyle="normal" lineHeight="1.4em" color="#F5F8FFC9" letterSpacing="0.6px">
          Photographer
        </Heading>

        <Text fontFamily="Sen" fontSize={["16px", "16px", "18px", "18px"]} lineHeight="1.6em" w="100%" color="#F5F8FFC9" mt={["20px", "20px", "30px", "40px"]}>
          I am an adventurous photographer who loves to capture the beauty of the world around me. I have a passion for capturing the unique and unexpected moments that life has to offer. Whether it be stunning wildlife that nature has to offer, a captivating portrait, or a vibrant cityscape. With an eye for detail and an appreciation for the little things, I love to click images that capture the beauty of the world.
        </Text>

        <Box>
          <Image w="150px" h="150px" src="assest/logos.png" mt="-30px" alignSelf="flex-start" />
        </Box>
      </Stack>
    </Stack>
  );
}

export default About;
