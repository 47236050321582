import React, { useState, useEffect } from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
  theme,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Divider,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  ModalCloseButton,
  Image,
  Checkbox,
  SimpleGrid,
} from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import { WhatsappShareButton } from 'react-share';
import { ImRedo2 } from 'react-icons/im';
import { FaWhatsapp } from 'react-icons/fa';
import '../Pages/styles.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import { Helmet } from 'react-helmet';

const downloadImage = (imageURL, filename) => {
  // Use Axios to download the image
  axios({
    url: imageURL,
    method: 'GET',
    responseType: 'blob',
  })
    .then(response => {
      // Create a URL for the blob object
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = filename + '.png';

      // Trigger a click event on the link element
      a.click();

      // Release the object URL to free up memory
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error downloading image:', error);
    });
};

function Photo() {
  const { userId } = useParams();
  const url = window.location.href;
  const {
    isOpen: isDrawerOpen,
    onOpen: isDraweronOpen,
    onClose: isDrawerOnClose,
  } = useDisclosure();
  const {
    isOpen: isShareOpen,
    onOpen: isShareonOpen,
    onClose: isShareOnClose,
  } = useDisclosure();
  const [isZoomed, setIsZoomed] = useState(false);
  var [fileInfo, setFileInfo] = useState({});
  const user = Cookies.get('userToken', { expires: 1 / 48 });
  // for image
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDownloadOpen, setIsModalDownloadOpen] = useState(false);
  // selected images
  const [selectedImages, setSelectedImages] = useState([]);

  // Function to handle the click on an image to open in a lightbox
  const handleImageClick = image => {
    if (!isModalDownloadOpen) {
      setSelectedImage(image);
      setIsModalOpen(true);
    } else {
      handleImageSelect(image);
    }
  };
  // Function to close the lightbox
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Function to close the download Modal
  const handleCloseDownloadModal = () => {
    setIsModalDownloadOpen(false);
  };

  // Function to close the download Modal
  const handleOpenDownloadModal = () => {
    setIsModalDownloadOpen(true);
  };

  const handleImageSelect = image => {
    // Toggle selection of images
    if (selectedImages.includes(image)) {
      setSelectedImages(
        selectedImages.filter(selectedImage => selectedImage !== image)
      );
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const handleAddToCart = () => {
    localStorage.setItem('selectedImages', JSON.stringify(selectedImages));
    window.location.href = '/cart';
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CLIENT_URL}get-watermark/${userId}`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then(response => {
        setFileInfo(response.data.data); // Assuming "data" contains the response object
        console.log(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    const handleContextmenu = e => {
      e.preventDefault();
      alert('Right-click is disabled.');
    };

    const handleKeydown = e => {
      // Prevent common keyboard shortcuts (e.g., F12, Ctrl+Shift+I)
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i')) ||
        (e.metaKey && e.altKey && e.key === 'I') || // Mac: Cmd + Option + I
        (e.ctrlKey && e.altKey && e.key === 'I') // Mac: Ctrl + Option + I
      ) {
        e.preventDefault();
        alert('Keyboard shortcuts are disabled.');
      }
    };

    document.addEventListener('contextmenu', handleContextmenu);
    document.addEventListener('keydown', handleKeydown);

    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [userId]);

  useEffect(() => {
    // Retrieve selected images from localStorage when the component mounts
    const storedSelectedImages = JSON.parse(
      localStorage.getItem('selectedImages')
    );
    if (storedSelectedImages) {
      setSelectedImages(storedSelectedImages);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>

      <Stack
        w="100%"
        h="full"
        bg="gray.800"
        direction={{ sm: 'column', md: 'row', lg: 'row' }}
      >
        <Stack
          w={{ sm: 'none', md: '10%', lg: '10%' }}
          h="100VH"
          display={{ sm: 'none', md: 'flex', lg: 'flex' }}
        >
          <HamburgerIcon
            boxSize={10}
            color="white"
            onClick={isDraweronOpen}
            alignSelf="center"
            mt={10}
          />
          {/* Drawer */}
          <Drawer
            placement="left"
            onClose={isDrawerOnClose}
            isOpen={isDrawerOpen}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader
                pt="10%"
                borderBottomWidth="1px"
                bgColor="gray.700"
                textColor="#D2C1AF"
              >
                Navigation
              </DrawerHeader>
              <DrawerCloseButton color="white" mt="5%" />
              <DrawerBody bgColor="gray.700">
                <Stack dir="column" spacing={5} mt={5}>
                  <Link _hover="none" href="https://rohitkandoth.com/">
                    <Button
                      fontFamily="Saira Condensed"
                      _hover={{ textColor: '#D2C1AF' }}
                      fontSize="20px"
                      variant="link"
                      fontWeight={600}
                      lineHeight="1.3em"
                      letterSpacing="0.4px"
                      fontStyle="normal"
                    >
                      HOME
                    </Button>
                  </Link>
                  <Link _hover="none" href="https://rohitkandoth.com/service/">
                    <Button
                      fontFamily="Saira Condensed"
                      _hover={{ textColor: '#D2C1AF' }}
                      fontSize="20px"
                      variant="link"
                      fontWeight={600}
                      lineHeight="1.3em"
                      letterSpacing="0.4px"
                      fontStyle="normal"
                    >
                      SERVICES
                    </Button>
                  </Link>
                  <Link
                    _hover="none"
                    href="https://rohitkandoth.com/portfolio/"
                  >
                    <Button
                      fontFamily="Saira Condensed"
                      _hover={{ textColor: '#D2C1AF' }}
                      fontSize="20px"
                      variant="link"
                      fontWeight={600}
                      lineHeight="1.3em"
                      letterSpacing="0.4px"
                      fontStyle="normal"
                    >
                      PORTFOLIO
                    </Button>
                  </Link>
                  <Link _hover="none" href="https://rohitkandoth.com/about-me/">
                    <Button
                      fontFamily="Saira Condensed"
                      _hover={{ textColor: '#D2C1AF' }}
                      fontSize="20px"
                      variant="link"
                      fontWeight={600}
                      lineHeight="1.3em"
                      letterSpacing="0.4px"
                      fontStyle="normal"
                    >
                      ABOUT ROHIT
                    </Button>
                  </Link>
                  <Link _hover="none" href="https://rohitkandoth.com/contact/">
                    <Button
                      fontFamily="Saira Condensed"
                      _hover={{ textColor: '#D2C1AF' }}
                      fontSize="20px"
                      variant="link"
                      fontWeight={600}
                      lineHeight="1.3em"
                      letterSpacing="0.4px"
                      fontStyle="normal"
                    >
                      CONTACT ME
                    </Button>
                  </Link>
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          {/* Drawer end */}
          {/* <Stack direction="column-reverse" justifyContent="end" w="full" h="45%">
          <Link href="tel:+44 7778675778" _focus="none">
            <Button
              variant="ghost"
              w="full"
              mb={20}
              transform="rotate(-90deg)"
              lineHeight="16px"
              fontFamily="Source Serif 4"
              fontWeight={theme.fontWeights.normal}
              bgColor="none"
            >
              +44 7778675778
            </Button>
          </Link>
        </Stack>
        <Stack
          direction="column-reverse"
          justifyContent="center"
          w="full"
          h="45%"
        >
          <Link href="mailto:contact@rohitkandoth.com" _focus="none">
            <Button
              w="100%"
              variant="ghost"
              fontSize={theme.fontSizes.lg}
              fontWeight={theme.fontWeights.normal}
              transform="rotate(-90deg)"
              lineHeight="16px"
              fontFamily="Source Serif 4"
            >
              contact@rohitkandoth.com
            </Button>
          </Link>
        </Stack> */}
        </Stack>
        <Stack
          alignSelf="center"
          spacing={10}
          w={{ sm: '100%', md: '40%', lg: '40%' }}
          p={{ sm: 5, md: 5, lg: 20 }}
          h="full"
        >
          {/* file head */}
          <HamburgerIcon
            boxSize={10}
            color="white"
            onClick={isDraweronOpen}
            alignSelf="left"
            mt={5}
            display={{ sm: 'flex', md: 'none', lg: 'none' }}
          />
          <Heading
            width={{ sm: 'full' }}
            fontSize={['5xl', '5xl', '5xl', '5xl', '6xl']}
            textColor="white"
          >
            {}
          </Heading>

          {/* file info */}
          <Box
            p={10}
            direction={{ sm: 'column', md: 'column', lg: 'row' }}
            border="5px solid #534141"
          >
            <Stack spacing={4}>
              <HStack>
                <Heading fontSize={20} color="#ffff">
                  Name :-
                </Heading>
                <Text textColor="white" fontSize={18}>
                  {fileInfo.name}
                </Text>
              </HStack>
            </Stack>
            <Stack
              justifyContent="space-around"
              mt={10}
              alignSelf="center"
              direction={['column', 'row', 'row']}
            >
              {/* <Button
              // onClick={() => showRazorpay(userId, fileInfo.name)}
              onClick={setIsModalDownloadOpen}
              rounded={20}
              textColor="white"
              bgColor="gray.700"
              border="2px solid #534141"
              _hover={{ bgColor: 'gray.800' }}
            >
              Download
            </Button> */}
              <Button
                rounded={20}
                border="2px solid #534141"
                textColor="white"
                rightIcon={<ImRedo2 />}
                bgColor="gray.700"
                _hover={{ bgColor: 'gray.800' }}
                onClick={isShareonOpen}
              >
                Share
              </Button>
              {/* pop up */}
              <Modal isOpen={isShareOpen} onClose={isShareOnClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader textColor="white" bgColor="gray.700">
                    Share{' '}
                  </ModalHeader>
                  <ModalBody bgColor="gray.700">
                    <Flex justifyContent="center">
                      <QRCode value={url} size={200} />
                    </Flex>
                    <Divider mt={5} border="2px light " />
                    <Flex justifyContent="center">
                      <WhatsappShareButton url={url}>
                        <Button
                          mt={10}
                          textColor="white"
                          leftIcon={<FaWhatsapp />}
                          _hover={{ bgColor: 'gray.800' }}
                          bgColor="gray.700"
                          mr="2"
                        >
                          Share on WhatsApp
                        </Button>
                      </WhatsappShareButton>
                    </Flex>
                  </ModalBody>
                  <ModalFooter bgColor="gray.700">
                    <Button
                      onClick={isShareOnClose}
                      textColor="white"
                      bgColor="gray.700"
                      _hover={{ bgColor: 'gray.800' }}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Stack>
          </Box>
        </Stack>

        <Stack
          w={{ base: '100%', md: '50%', lg: '50%', xl: '50%', '2xl': '50%' }}
          h="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            w="90%"
            h="50%"
            display="grid"
            gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))"
            gridGap="20px" // Adjust the grid gap as needed (for example, 5px)
            // Set responsive column widths for different screen sizes
            css={{
              '@media screen and (max-width: 767px)': {
                gridTemplateColumns: 'repeat(1, 1fr)',
              },
              '@media screen and (min-width: 768px) and (max-width: 1023px)': {
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
              '@media screen and (min-width: 1024px)': {
                gridTemplateColumns: 'repeat(3, 1fr)',
              },
            }}
          >
            {fileInfo.watermarked_file &&
              fileInfo.watermarked_file.map((image, index) => (
                <div key={index} onClick={() => handleImageClick(image)}>
                  <Image
                    src={
                      'https://api.rohitkandothphotography.com/uploads/' + image
                    }
                    alt={`Image ${index}`}
                    style={{
                      objectFit: 'cover',
                      width: '200px',
                      height: '200px',
                      transition: 'transform 0.3s',
                      borderRadius: '8px',
                      boxShadow:
                        '0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1)',
                      cursor: 'zoom-out',
                    }}
                    _hover={{
                      transform: 'scale(1.05)',
                      filter: 'brightness(90%)',
                      boxShadow:
                        '0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                </div>
              ))}
          </Stack>
        </Stack>

        {/* Lightbox Modal */}
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="full">
          <ModalOverlay />
          <ModalContent bg="gray.800">
            <ModalCloseButton color="white" />
            <ModalBody>
              {selectedImage && (
                <Image
                  src={
                    'https://api.rohitkandothphotography.com/uploads/' +
                    selectedImage
                  }
                  alt={selectedImage}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100vh',
                  }}
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* for image select modal */}
        <Modal
          isOpen={isModalDownloadOpen}
          onClose={handleCloseDownloadModal}
          size="full"
        >
          <ModalOverlay />
          <ModalContent bg="gray.800">
            <ModalHeader>
              Image Selection
              <ModalCloseButton color="white" />
            </ModalHeader>
            <ModalBody>
              <SimpleGrid columns={[1, 2, 4]} spacing="20px">
                {fileInfo.watermarked_file &&
                  fileInfo.watermarked_file.map((image, index) => (
                    <Box key={index} position="relative">
                      <Checkbox
                        position="absolute"
                        top="5px"
                        left="5px"
                        zIndex="1"
                        colorScheme="green"
                        isChecked={selectedImages.includes(image)}
                        onChange={() => handleImageSelect(image)}
                        style={{ opacity: 1, width: '20px', height: '20px' }}
                        _checked={{
                          display: 'block',
                          backgroundColor: 'white',
                        }}
                        _focus={{ outline: 'none' }}
                        _hover={{ opacity: 1 }}
                      />
                      <Image
                        src={
                          'https://api.rohitkandothphotography.com/uploads/' +
                          image
                        }
                        alt={`Image ${index}`}
                        style={{
                          objectFit: 'cover',
                          width: '100%',
                          height: '100%',
                          transition: 'transform 0.3s',
                          borderRadius: '8px',
                          boxShadow:
                            '0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1)',
                          cursor: 'pointer',
                        }}
                        _hover={{
                          transform: 'scale(1.05)',
                          filter: 'brightness(90%)',
                          boxShadow:
                            '0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1)',
                        }}
                        onClick={() => handleImageSelect(image)}
                      />
                    </Box>
                  ))}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter justifyContent="center" marginBottom="20px">
              <Button onClick={handleAddToCart} mt="20px">
                Add to Cart
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
}

export default Photo;
