import React, { useState, useRef } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomeHead.css'; // Import the CSS file for the animations
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";

function HomeHead({ backgroundImages }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)', // Easing function for smoother transitions
    beforeChange: (prev, next) => setCurrentSlide(next),
    arrows: false, // Disable default arrows
  };

  const goToPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {backgroundImages &&
          backgroundImages.map((backgroundImage, index) => (
            <>
              <div className="custom-arrows">
                <div className="arrow left" onClick={goToPrev}>
                  <BiLeftArrowCircle size={40} color="white" />
                </div>
                <div className="arrow right" onClick={goToNext}>
                  <BiRightArrowCircle size={40} color="white" />
                </div>
              </div>
              <Flex
                key={index}
                className={`kenburns-animation ${
                  currentSlide === index ? 'active' : ''
                }`}
                backgroundImage={`url(${backgroundImage})`}
                bgPosition="center center"
                backgroundAttachment="fixed"
                w="100%"
                h="100vh"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
              >
                <Stack
                  alignSelf="center"
                  w="full"
                  h="full"
                  p={[10, 0, 0]}
                  justifyContent="center"
                  bgColor="#0B0C0C"
                  opacity={0.2}
                  bgBlendMode="overlay"
                ></Stack>
              </Flex>
            </>
          ))}
      </Slider>

      {/* Custom Arrows */}
    </div>
  );
}

export default HomeHead;

// import React, { useState } from 'react';
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css';
// import { Flex, Stack } from '@chakra-ui/react';
// import './HomeHead.css';

// function HomeHead({ backgroundImages }) {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const onSlideChange = (previous, next) => {
//     setCurrentSlide(next);
//   };

//   return (
//     <div className="slider-container">
//       <Slide
//         images={backgroundImages}
//         indicators={false}
//         arrows={false}
//         duration={3000}
//         transitionDuration={1000}
//         autoplay
//         onChange={onSlideChange}
//       >
//         {backgroundImages.map((backgroundImage, index) => (
//           <div
//             key={index}
//             className={`each-slide ${currentSlide === index ? 'active' : ''}`}
//           >
//             <Flex
//               className="kenburns-animation"
//               backgroundImage={`url(${backgroundImage})`}
//               bgPosition="center center"
//               backgroundAttachment="fixed"
//               w="100%"
//               h="100vh"
//               backgroundRepeat="no-repeat"
//               backgroundSize="cover"
//             >
//               <Stack
//                 alignSelf="center"
//                 w="full"
//                 h="full"
//                 p={[10, 0, 0]}
//                 justifyContent="center"
//                 bgColor="#0B0C0C"
//                 opacity={0.2}
//                 bgBlendMode="overlay"
//               >
//                 {/* Your content */}
//               </Stack>
//             </Flex>
//           </div>
//         ))}
//       </Slide>
//     </div>
//   );
// }

// export default HomeHead;
