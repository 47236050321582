import HomeNavbar from '../Component/HomeNavbar';
import CartComponent from '../Component/CartComponent';
import CommonNavBar from '../Component/CommonNavBar';

export default function Cart() {

  return (
    <>
      {/* <CommonNavBar /> */}
      <CartComponent />
    </>
  );
}
