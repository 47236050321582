import React from 'react'
import CommonNavBar from '../Component/CommonNavBar'
import PortfolioGallery from '../Component/PortfolioGallery'
import { Helmet } from 'react-helmet'

export default function Gallery() {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Gallery</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
    <CommonNavBar />
    <PortfolioGallery />
    </>
  )
}
