import { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Button,
  Stack,
  SimpleGrid,
  Box,
  Image,
} from '@chakra-ui/react';
import HomeNavbar from '../Component/HomeNavbar';
import { useNavigate } from 'react-router-dom';

export default function CartComponent() {
  const cartItems = [
    // Sample static cart items for display
    {
      productId: 1,
      size: 'M',
      dimensions: '10x10',
      quantity: 2,
      data: { name: 'Sample Product 1' },
    },
    {
      productId: 2,
      size: 'L',
      dimensions: '12x12',
      quantity: 1,
      data: { name: 'Sample Product 2' },
    },
    // Add more sample items as needed
  ];

  const subTotal = 150; // Static subtotal value

  const handleCheckout = () => {
    // Your checkout logic here
  };

  const [selectedPhotos, setSelectedPhotos] = useState([]); // State to hold selected photos

  useEffect(() => {
    // Retrieve selected images from localStorage when the component mounts
    const storedSelectedImages = JSON.parse(
      localStorage.getItem('selectedImages')
    );
    if (storedSelectedImages) {
      setSelectedPhotos(storedSelectedImages);
    }
  }, []);

  // navigation
  const navigate = useNavigate();

  return (
    <Stack
      w="100%"
      minH="100vh"
      direction={{ base: 'column', md: 'row', lg: 'row' }}
      spacing="10"
      p={{ base: 5, md: 20 }}
      bg="gray.800"
      color="white"
    >
      <Stack
        w={{ base: '100%', md: '40%', lg: '70%' }}
        h={{ base: '100%', md: '100%', lg: '90vh' }}
        alignItems="center"
        spacing="10"
      >
        {/* Left part for displaying selected images */}
        <Box w="100%" overflow="auto">
          <Text fontSize="2xl" fontWeight="semibold" mb="20px">
            Selected Iamges
          </Text>
          <SimpleGrid columns={[1, 2, 3]} spacing="20px">
            {selectedPhotos.map((image, index) => (
              <Box key={index} position="relative">
                <Image
                  src={`https://api.rohitkandothphotography.com/uploads/${image}`}
                  alt={`Product Image ${index}`}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    transition: 'transform 0.3s',
                    borderRadius: '8px',
                    boxShadow:
                      '0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1)',
                    cursor: 'pointer',
                  }}
                  _hover={{
                    transform: 'scale(1.05)',
                    filter: 'brightness(90%)',
                    boxShadow:
                      '0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  // onClick={() => handleImageSelect(image)}
                />
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Stack>

      {/* Right part for displaying cart totals */}
      <Stack
        w={{ base: '100%', md: '40%', lg: '20%' }}
        h={{ base: '100%', md: '100%', lg: '90vh' }}
        borderLeft={{ base: '', md: '2px solid white', lg: '2px solid white' }}
        alignItems="center" justifyContent="center"
        spacing="10"
      >
        <Flex direction="column" p="5">
          <Text fontSize="2xl" fontWeight="semibold">
            Cart Totals
          </Text>
          <Flex justify="space-between" align="center" mt="5">
            <Text fontSize="2xl" fontWeight="light">
              Total
            </Text>
            <Text fontSize="2xl" fontWeight="medium">
              ₹{subTotal}
            </Text>
          </Flex>
          <Button
            bg="#0093FE"
            color="white"
            fontSize="lg"
            mt="10"
            onClick={handleCheckout}
            _hover={{ background: 'transparent', color: '#0093FE' }}
          >
            Payment
          </Button>
          <Flex align="center" justify="center" mt="5">
            <Button
              fontSize="lg"
              bgColor="transparent"
              onClick={() => navigate(-1)}
              _hover={{ background: 'transparent' }}
              color="white"
            >
              Continue Selecting ?
            </Button>
          </Flex>
        </Flex>
      </Stack>
    </Stack>
  );
}
