import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Head from '../Component/Head';
import TourContent from '../Component/TourContent';
import Footer from '../Component/Footer';
import { Helmet } from 'react-helmet';
import HomeNavbar from '../Component/HomeNavbar';

export default function SpecificTour() {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.rohitkandothphotography.com/Safari/layout/${id}`
        );
        const result = await response.json();
        setData(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Safarai Detail</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <HomeNavbar />
      <Head
        backgroundImage={`https://api.rohitkandothphotography.com/${data?.featured_banner}`}
      />
      <TourContent heading={data?.heading} content={data?.Safari_body} tagline={data?.tagline} />

      <Footer />
    </>
  );
}
