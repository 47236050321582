import React, { useState } from 'react';
import {
  Box,
  Heading,
  Input,
  Stack,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function AuthPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [isLogin, setIsLogin] = useState(true);

  const toggleForm = () => {
    setIsLogin((prevIsLogin) => !prevIsLogin);
  };

  const onSubmit = async (values, formikHelpers) => {
    const { setSubmitting, setErrors } = formikHelpers;

    try {
      setIsLoading(true);
      const endpoint = isLogin
        ? `${process.env.REACT_APP_CLIENT_URL}login`
        : `${process.env.REACT_APP_CLIENT_URL}register`;

      const response = await axios.post(endpoint, {
        name: isLogin ? '' : values.name, // Only send the name during registration
        email: values.email,
        password: values.password,
      });

      console.log('Authentication Successful:', response.data);

      const newToken = response.data.token;

      Cookies.set('userToken', newToken, { expires: 1 / 48 });

      setIsLoading(false);
      window.location =
        searchParams.get('redirect') == null ? '/' : searchParams.get('redirect');
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 401) {
        setErrors({ password: 'Invalid credentials' });
      } else if (error.response && error.response.status === 404) {
        setErrors({ password: 'Invalid credentials' });
      } else {
        console.error('Authentication Error:', error);
        alert('There is some issue');
      }
    }
  };

  const validationSchema = Yup.object({
    name: isLogin ? undefined : Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <>
    <Helmet>
    <meta charSet="utf-8" />
    <title>Login</title>
    {/* <link rel="canonical" href="http://mysite.com/example" /> */}
  </Helmet>
    <Stack
      bgColor="gray.800"
      w="full"
      h="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        alignSelf="center"
        textAlign="center"
        border="2px solid white"
        w={{ sm: '90%', lg: '50%', xl: '50%' }}
        pb={10}
        h="max-content"
      >
        <Heading textColor="white" borderBottom="2px solid white">
          {isLogin ? 'Login' : 'Register'}
        </Heading>

        <VStack
          as="form"
          h="full"
          justifyContent="center"
          onSubmit={formik.handleSubmit}
          p={{ sm: 10, lg: 5 }}
          spacing={10}
        >
          {!isLogin && (
            <FormControl
              w="90%"
              isRequired
              isInvalid={formik.errors.name && formik.touched.name}
            >
              <FormLabel textColor="white">Name</FormLabel>
              <Input
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Enter your name"
                color="white"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
          )}

          <FormControl
            w="90%"
            isRequired
            isInvalid={formik.errors.email && formik.touched.email}
          >
            <FormLabel textColor="white">Email</FormLabel>
            <Input
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="Enter your email"
              color="white"
            />
            <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
          </FormControl>

          <FormControl
            w="90%"
            isRequired
            isInvalid={formik.errors.password && formik.touched.password}
          >
            <FormLabel textColor="white">Password</FormLabel>
            <Input
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              type="password"
              placeholder="Enter your password"
              color="white"
            />
            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
          </FormControl>

          <Button
            type="submit"
            variant="outline"
            alignSelf="center"
            background="white"
            colorScheme="white"
          >
            {isLoading ? 'Loading...' : isLogin ? 'Login' : 'Register'}
          </Button>

          <Text style={{ color: 'white' }}>
            {isLogin
              ? "Don't have an account?"
              : 'Already have an account?'}
            <button
              onClick={toggleForm}
              style={{
                color: '#4076ff',
                cursor: 'pointer',
                marginLeft: '5px',
                textDecoration: 'underline',
                background: 'none',
                border: 'none',
                padding: '0',
                font: 'inherit',
                cursor: 'pointer',
              }}
            >
              {isLogin ? 'Register here' : 'Login here'}
            </button>
          </Text>
        </VStack>
      </Box>
    </Stack>
    </>
  );
}
