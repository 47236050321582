import React from 'react';
import About from '../Component/About';
// import AboutHead from '../Component/AboutHead'
import CommonNavBar from '../Component/CommonNavBar';
// import ContactHead from '../Component/CommonHead'
import Footer from '../Component/Footer';
import { Helmet } from 'react-helmet';

function AboutMe() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Me</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <CommonNavBar />
      <About />
      <Footer />
    </>
  );
}

export default AboutMe;
