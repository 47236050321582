import {
  Box,
  Heading,
  Input,
  Stack,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Register() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const onSubmit = async (values, formikHelpers) => {
    const { setSubmitting, setErrors } = formikHelpers;

    try {
      setIsLoading(true);
      // Make API request to login using values.email and values.password
      const response = await axios.post(
        `${process.env.REACT_APP_CLIENT_URL}register`,
        {
          name: values.name,
          email: values.email,
          password: values.password,
        }
      );

      // Assuming the response contains a token or user data, handle it here
      console.log('Login Successful:', response.data);

      const newToken = response.data.token;

      // Store the token in a cookie
      Cookies.set('userToken', newToken, { expires: 1 / 48 }); // 1/48 represents 30 minutes (24 hours / 48)

      setIsLoading(false);
      window.location =
        searchParams.get('redirect') == null
          ? '/'
          : searchParams.get('redirect');

      // Handle successful login response here, e.g., redirect or set user state
    } catch (error) {
      // Handle login error and display error message
      if (error.response && error.response.status === 401) {
        setIsLoading(false);
        setErrors({ password: 'Invalid credentials' });
      } else if (error.response && error.response.status === 404) {
        setIsLoading(false);
        setErrors({ password: 'Invalid credentials' });
      } else {
        setIsLoading(false);
        console.error('register Error:', error);
        alert('There is some issue');
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <Stack
        bgColor="gray.800"
        w="full"
        h="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          alignSelf="center"
          textAlign="center"
          border="2px solid white"
          w={{ sm: '90%', lg: '50%', xl: '50%' }}
          pb={10}
          h="max-content"
        >
          <Heading textColor="white" borderBottom="2px solid white">
            Login
          </Heading>

          <VStack
            as="form"
            h="full"
            justifyContent="center"
            onSubmit={formik.handleSubmit}
            p={{ sm: 10, lg: 5 }}
            spacing={10}
          >
            <FormControl
              w="90%"
              isRequired
              isInvalid={formik.errors.name && formik.touched.name}
            >
              <FormLabel textColor="white">Name</FormLabel>
              <Input
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="naruto"
                color="white"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl
              w="90%"
              isRequired
              isInvalid={formik.errors.email && formik.touched.email}
            >
              <FormLabel textColor="white">Email</FormLabel>
              <Input
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="naruto@gmail.com"
                color="white"
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl
              w="90%"
              isRequired
              isInvalid={formik.errors.password && formik.touched.password}
            >
              <FormLabel textColor="white">Password</FormLabel>
              <Input
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                type="password"
                placeholder="**********"
                color="white"
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              variant="outline"
              alignSelf="center"
              background="white"
              colorScheme="white"
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </Button>

            <text style={{ color: 'white' }}>
              Alredy Login ?
              <a href="/register">
                {' '}
                <text style={{ color: '#4076ff' }}> login here </text>
              </a>
            </text>
          </VStack>
        </Box>
      </Stack>
    </>
  );
}
